body {
  margin: 0;
  padding: 0;
  height: 90% !important;
  font-family: 'Montserrat', sans-serif;
}

header {
  overflow: hidden;
  position: fixed;
  font-weight: 800;
  position: relative;
  z-index: 100;
  background-color: #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 2px 8px 18px #ccc;
  position: fixed;
  top: 0;
  width: 100%; 
}

h1 {
  font-size: 3.2rem;
}

header h2 {
  text-align: center;
  padding: 15px 15px 15px 30px;
  margin: 0;
  font-weight: 700;
}

header h2 a:hover {
  color: rgb(154, 152, 152);
}

header h2 a, nav li a {
  text-decoration: none;
  color: #000;
}

.nav button {
  background: #000;
  color: #fff;
  font-weight: 600;
  padding: 5px 12px;
  border-radius: 5px;
  margin-left: 5px;
  border: 3px solid #fff;
}

.nav button:hover {
  cursor: pointer;
  background: #313131;
} 

.nav button a {
  color: #fff;
}

nav li a {
  font-weight: 400;
}

nav li {
  list-style: none;
  display: inline-block;
  padding: 2px 15px;
  font-size: 1.2em;
}

nav li a {
  position: relative;
  padding: 4px 0;
}

nav li a:hover {
  color: rgb(154, 152, 152);
  -webkit-transition: all 0.5s ease-in-out 0s;

}

nav li a:active {
  color: #ccc;
}


/* Link Animation */

nav li a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #43433e;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.7s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media only screen and (max-width: 780px){

  body {
    margin: 0;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px;
  }

  .dropdown-menu i {
    font-size: 2em;
    border-radius: 10%;
    cursor: pointer;
    margin: 18px;
    padding-top: 3px;
  }

  nav li {
    padding: 20px;
  }

  .dropdown-menu h2 {
    padding-right: 30%;
  }

  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 0;
  }
}

@media only screen and (max-width: 500px){

  body {
    margin: 0;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
  }

  .dropdown-menu i {
    font-size: 2em;
    border-radius: 10%;
    cursor: pointer;
    margin: 18px;
    padding-top: 3px;
  }

  .nav li {
    display: block;
    float: left;
    width: 100%;
    padding: 10px;
    text-decoration: none;
  }

  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 0;
  }
}


/* Footer */

.footItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
}

.foot {
  display: flex;
  flex-direction: row;
}

.foot a {
  padding: 10px;
  font-size: 1.4em;
}

.twit {
  color: #aaddff;
}

.link {
  color: #9b6ff2;
}

.git {
  color: #80f7ed;
}

.camp {
  color: #78fc76;
}

.foot a {
  font-size: 1.6em;
  padding: 16px;
}

/* Footer Links */

.footLinks ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.footLinks ul li {
  padding: 12px;
  font-size: .7em;
}


/* Goals Section */

.goals {
  background: linear-gradient(80deg, #ccdbce 20%, rgb(154, 196, 243) 50%, transparent), url('https://hillschurch.files.wordpress.com/2017/02/mountaintop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5%;
}

.goals h1 {
  margin: 0;
  font-size: 3.4rem;
  font-weight: 800;
}

.goals p {
  padding: 20px 0 0 0;
  font-size: 1.4em;
  width: 50%;
}

.goals ol {
  margin: 20px 10px 0 40px;
  padding: 0;
}

.goals ol li {
  color: rgb(167, 87, 241);
  font-size: 1.8rem;
  line-height: 1.4em;
  padding: 10px 8px;
}

.goals ol li:hover {
  cursor: pointer;
}

.complete {
  text-decoration: line-through;
}

.trash.icon {
  font-size: 20px;
  padding-left: 10px;
}

.trash.icon:hover {
  color: rgb(178,31,31) !important;
  cursor: pointer;
}

/* goal input */

.goal-section {
  padding: 5px;
  align-content: center;
}

.goal-list {
  width: 50%;
}

.goal-input {
  width: 98%;
  margin-left: 10%;
  border-radius: 5px;
  box-shadow: none;
  margin: 2px;

}

.buttons {
  border: 2px solid black;
  background-color: white;
  margin: 20px 0 0 0;
  font-size: 2px;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 800;
}

.info {
  border-color: #2196F3;
  font-size: .7em;
  color: dodgerblue
}

.info:hover {
  background: #2196F3;
  color: white;
  -webkit-transition: all 0.5s ease-in-out 0s;
}


button:focus, input:focus {
  outline: none;
}

/* Tweets */

.tweets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px 0;
}

.tweets-copy {
  width: 40%;
  margin: 5% 0;
  padding: 10px 10px 10px 24px;
}

.twitter-section {
  display: flex;
  flex-direction: column;
  width: 50%;
}

/* Skills */

.technologies {
  background: #f0f0f5;
  padding: 5%;
}

.tech-header h2 {
  display: flex;
  font-size: 2em; 
}

.tech-header p {
  padding-bottom: 3%;
}

.tech-header-border {
  border-bottom: 1px solid black;
  margin: 0 0 0 -120px;
}

.tech-header-border-right {
  border-bottom: 1px solid black;
  margin: 0 -120px 0 0;
}

@media only screen and (max-width: 820px){
  .tech-header-border-right {
    margin: 0 -35px 0 0;
  }

  .frontend-section {
    flex-direction: column;
  }

  .collages img {
    width: 100%;
  }
}

.skill-section {
  width: 100%;
}

.collages {
  width: 50%;
  align-content: center;
}

.copy p, .bio p, .favorites p {
  font-size: 1.2em;
  line-height: 1.6em;
}

.frontend-section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 0;
  width: 100%
}

.frontend-copy {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 40%;
}

.frontend-copy ul li, .backend-copy ul li {
  font-size: 2em;
  line-height: 2em;
}

.backend-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding: 40px 0;
  width: 100%;
  text-align: left;
}

.backend-copy {
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 40%;
}

.future-section {
  padding-top: 30px;
}

.future-copy {
  padding-bottom: 3%;
}

.near-future-section h2 {
  padding-top: 50px;
}

.near-future-list {
  display: flex;
  flex-direction: row;
  padding: 40px 0;
}

.near-future-list ol {
  width: 40%;
  padding-right: 5%;
  margin-right: 10%;
}

.near-future-list ol li {
  font-size: 2em;
  line-height: 2em;
}

.distant-future-section {
  padding: 50px 0;
}

.long-term-list {
  display: flex;
  flex-direction: row; 
}

.long-term-list ol {
  width: 40%;
  padding:  0 100px;
  margin: 50px;
}

.long-term-list ol li {
  font-size: 2em;
  line-height: 2em;
}

/* Tweets */

.tweets p {
  padding-top: 16px;
  font-size: 1.4em;
}

.follow-button {
  padding: 20px;
}

/* Projects */

.projects {
  padding: 3%;
}

.project {
  padding-bottom: 5%;
}

.website {
  display: flex;
  display: row;
  padding: 2%;
  margin-top: 3%;
}

.site-pic {
  width: 40vw;
  border: 1px solid #ccc;
}

.website ul li {
  padding: 8px;
}

.image {
  display: flex;
  flex-direction: column;
}

.links {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.links i {
  font-size: 1.4em;
}

.link {
  display: inline-block;
  padding: 15px;
  font-size: 1.5em;
}

.link a {
  padding-left: 4px;
}

.built-with p {
  padding-top: 14px;
}

.built-with {
  display: flex;
  justify-content: center;
}

.built-with img {
  padding-left: 8px;
  width: 180px;
}

/* comment form */

#comment-form {
  padding: 20px 0 40px 0;
}

#comment-form textarea {
  margin-bottom: 15px;
}

.comment-spacing p {
  line-height: 25px;
  padding: 5px;
}

.comment-spacing {
  padding: 10px;
  width: 100%;
}

/* blog */

.container.blog {
  margin: 10% auto 2% auto;

}

.blog-description {
  font-size: 24px;
  font-weight: 400;
}

.blog-body {
  margin: 40px 0;
  font-size: 1.3em;
  line-height: 1.4em;
}

.blog-body .author {
  font-weight: 600;
  margin-bottom: 2px;
}

.blog-body .post-date {
  margin-bottom: 1rem;
}

.blog-body pre {
  background: black;
  color: white;
}

.image-sizing {
  width: 80%;
}

#comments.container {
  margin: 2% auto;
}


/* Blog WYSIWGI */

.ql-editor {
  min-height: 400px;
}

/* Footer */

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #e8e8e8;
  padding: 10px;
  
}

.footer p {
  font-size: 1.2em;
  font-weight: 600;

}

.icons {
  font-size: 1.8em;
}

.icons i {
  padding: 10px;
  margin: 5px;
}

/* Model Login Buttons */

.section-column {
  display: flex;
  flex-direction: row;
}

#or {
  margin: 0 auto;
  padding-top: 10px;
}

.login-buttons {
  padding: 10px;
}

.login-buttons a {
  color: white;
}

.login-buttons button {
  width: 58vw;
}

.form {
  padding-top: 30px;
}

.button {
  padding: 10px;
}

/* Sign Up Form */

.signup-form {
  padding: 40px;
  margin: 10% auto;
  max-width: 600px !important;
  background: rgb(243, 243, 243);
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 8px 18px #ccc;
}

.signup-form fieldset {
  margin: 10px 0;
  padding: 20px 0;
}

.signup-btns {
  margin: 10px 10px 10px 0;
}

.signup-btn {
  padding-right: 10px;
}

.signup-btn a {
  color: white;
}

/* Blog Posts */

.container.blog-cards {
  min-height: max-content;
}

.header h4 {
  margin: 4px 0;
}

.card-header {
  margin: 4px 0;
}

.card-header h4 {
  margin: 4px 0;
}

.blog-card {
  width: 30%;
}

.blog-card p {
  margin: 12px 5px 20px 5px;
}

.ui.raised.segment {
  margin: 0 20px 40px 20px;
}

.blog-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px;
}

.post-details button.ui.button {
  background: #000;
}

.ui.button a {
  color: #fff;
}

.post-details button.ui.button:hover {
  background: #313131;
}

pre {
  padding: 10px;
}

.blog-image {
  margin-top: 12px;
  border-radius: 10px;
  width: 100%;
}

.post-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
}

.blog-icons span {
  margin: 0 0 0 10px;
}

.blog-inputs {
  padding: 8px;
}

.form-buttons {
  padding-top: 20px;
}

.add-blog {
  padding: 8px;
}

/* Like Buttons */

.like-buttons {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.like-buttons i {
  font-size: 18px;
}

.likes {
  padding: 20px;
}

@media only screen and (max-width: 1030px){

  .website {
    display: flex;
    flex-direction: column-reverse;
  }

  .container.projects {
    margin: 12% auto;
  }

  .site-pic {
    width: 100%;
  }

  .ui.raised.segment {
    width: 90%;
    margin: 20px auto;
  }
}

.ui.standard.modal {
  width: 25%;
}

button.ui.button.signin, .ui.placeholder.segment .column .field, .submit.button {
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

@media only screen and (min-width: 1020px){

  .collages img {
    width: 100%;
    height: 100%;
  }
}


/* About */

.bio {
  display: flex;
}

.about {
  padding: 10px 24px 10px 0;
  margin-bottom: 40px;
}

.bio-photo {
  margin: 60px 0 10px 24px;
  width: 400px;
  border-radius: 50%;
  float: right; 
}

.favorites {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

.box {
  margin: auto;
  width: 80%;
  padding: 30px;

}

.box h1 {
  padding: 16px;
}

.box p {
  padding: 0 28px;
  line-height: 25px;
}

.section {
    border-bottom: 1px solid black;
    width: 130%;
    margin: 0 -240px 60px;
}

.section-right {
  border-bottom: 1px solid black;
  width: 125%;
  margin: 60px 240px 0 0;
}

.book-img {
  height: 300px;
  width: 200px;
  box-shadow: 2px 8px 18px #ccc;
}

.album-img {
  height: 400px;
  width: 400px;
  box-shadow: 2px 8px 18px #ccc;
}

@media only screen and (max-width: 1024px){

  .bio-photo {
    float: none;
    margin: 10px;
  }
}

@media only screen and (max-width: 600px){
  .section-right, .section {
    width: 100%;
    padding: 20px 0;
    margin: 0;
  }

  .bio-photo {
    width: 300px;
  }
}

/* favorites */

.list-sections {
  padding: 20px;
}

.list-item {
  height: 100px
}

.lists {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 20px;
  margin: 0 auto;
}

.lists i {
  font-size: 3.4em;
  margin: 0 auto;
}

.lists h2 {
  margin: 0 auto;
}

.lists ol { 
  line-height: 2.4em;
}

.lists li {
  margin: 16px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 670px){
  .box {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .box h3 {
    width: 80%;
  }

  .album-img {
    height: 200px;
    width: 200px;
  }
}

@media only screen and (min-width: 1100px){


  .list-sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 0 40px 60px;
    width: 50%;
  }

  .list-sections.music {
    margin: 0 auto;
    padding-left: 0;
  }

  .list-sections.music ol {
    padding: 0;
  }

  .album-img {
    width: 600px;
    height: 600px;
  }


  .lists-sections ol {
    margin: 0;
  }

  .lists-sections ol li {
    padding: 0;
  }

  .favorites-lists {
    display: flex;
    flex-direction: row;
  }
}

/* Home Page Cover Image */

.cover {
  background-image: url('../images/10-brightest-stars.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top; 
  padding: 140px 20px 80px 20px;
}

.cover h1 {
  color: #93ecb5;
  margin: 0;
  padding: 0;
  font-size: 8vw;
  font-weight: 800;
  padding-top: 0;
}

.cover p {
  color: #93ecb5;
  font-size: 22px;  
}


@media only screen and (max-width: 1340px){

  .cover {
    height: 70%;
  }
}

@media only screen and (max-width: 680px){
  .cover {
    height: 400px;
    padding-top: 0;
  }

  h2 {
    padding-bottom: 0;
  }

  .sub-head, .tweets-copy p {
    margin: 0 2%;
    text-align: left;
  }
}

/* Music Page */

.playlist {
  height: 100vh;
}

.container {
  margin: 8% auto;
  max-width: 1280px;
  min-height: 80vh;
}

/* Picture */

.photos {
  margin: 3%;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.photos img {
  border: 1px solid rgb(221, 218, 218);
  padding: 14px 14px 60px 14px;
  margin: 10px;
  width: 31%;
  box-shadow: 2px 8px 18px #ccc;
}


@media only screen and (max-width: 1020px){
  .photos {
    width: 100%;
    margin: 0 auto;
  }

  .photos img {
    width: 400px;
    height: 400px;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 600px){

  .nav button {
    margin-bottom: 10px;
  }

  .login-buttons button.ui {
    width: 100%;
  }

  h1, .goals h1 {
    font-size: 2.2rem;
  }

  .cover h1 {
    padding-top: 16vh;
  }

  .tech-header-border-right {
    border-bottom: 1px solid black;
    margin: 0 -15px 0 0;
  }
  
  .collages img {
    width: 300px;
  }

  .goal-list {
    width: 100%;
  }

  .tweets {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 10px;
  }

  .twitter-section, .tweets-copy {
    padding: 2%;
    margin: 0;
    width: 100%;
  }

  .tweets p {
    padding: 0;
  }

  .frontend-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 40px 0;
    width: 100%;
  }

  .frontend-copy {
    width: 95%;
  }

  .backend-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    flex-wrap: wrap-reverse;
    flex-wrap: wrap;
    padding: 40px 0;
    width: 100%;
  }

  .backend-copy {
    width: 95%;
  }

  .distant-future-section {
    display: flex;
    flex-direction: column;
  }

  .near-future-list {
    display: flex;
    flex-direction: column-reverse;

  }

  .near-future-list ol {
    width: 100%;
  }

  .long-term-list {
    display: flex;
    flex-direction: column;
  }

  .long-term-list ol {
    padding: 0;
  }

  .collages img {
    width: 90vw;
  }

  .blog-card {
    width: 90%;
  }

  .blog-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px;
  }

  .ui.raised.segment {
    width: 90%;
  }

  #comments {
    padding: 5%; 
  }

  .project {
    padding: 10px;
  }

  .container.projects, .container {
    margin: 25% auto;
  }
  .goals {
    padding: 20px;
  }

  .goals p {
    width: 100%;
  }

  .blog-body {
    padding: 10px;
    background: white;
    margin: 0;
  }

  .ui.standard.modal.visible.active {
    width: 90%;
  }

  pre {
    font-size: .6em;
  }
  
  .ui.raised.segment {
    padding: 5px;
  }

  .list-sections {
    padding: 0;
  }

  .photos img {
    display: block;
    margin: 10px auto;
    max-width: 90%;
  }

  .signup-form {
    padding: 20px 30px;
  }

  .form-container {
    padding: 15px;
  }

}





